import { React, useEffect } from 'react';
import Header from '../.shared/header';
import Footer from '../.shared/footer';
import Steps from './Steps';
import Support from './Support';
import SuccessStories from './SuccessStories';
// import QuestionHero from './QuestionHero';
import VialHero from './VialHero';
import StartMyJourney from './StartMyJourney';



    

function Landing() {

    return (
        <>
            <Header />
            {/* <QuestionHero /> */}
            <VialHero />
            <Support />
            <Steps />
            <StartMyJourney />
            <SuccessStories />
            <Footer />
        </>
    );
}

export default Landing;
